import ShuffleArray from './ShuffleArray'

const RandomProfiles = (url,qty,callback = false) => {
  fetch(url)
  .then(response => response.json())
  .then(data => {
    if(callback){
      callback(ShuffleArray(data.data).filter(e => {
        console.log(e.thumbnail)
        return typeof(e.thumbnail) == 'undefined' ? false : true
      }).slice(0,qty))
    }
  });
}

export {RandomProfiles}