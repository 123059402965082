import ready from "./utilities/ready"
/* Add custom modules to this site */
import {RandomProfiles} from './components/RandomProfiles';
import LazyLoad from "vanilla-lazyload"
import accordion from 'aria-accordion';
 
// Required element to turn into an accordion
var elm = document.querySelector('.js-accordion'); 
if(elm){
  new accordion.Accordion(elm,null,{
    reflectStatic: true,
    customHiding: false
  });
}

const lazyLoadInstance = new LazyLoad({
  elements_selector: ".lazy"
});

function htmlDecode(input){
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

function populateAttorneys(result){
  const target = document.querySelector("#random-attorneys .container > .flex")
  result.forEach( function (attorney, key) {
    let frag = document.getElementById('random-post-template').content.cloneNode(true);
    frag.querySelector(".post-title a").setAttribute("href", attorney.permalink);
    frag.querySelector(".post-thumbnail").setAttribute("href", attorney.permalink);
    frag.querySelector(".post-title .name").textContent = attorney.name;
    frag.querySelector(".post-title .position").textContent = attorney.params.name.position;
    frag.querySelector(".post-thumbnail").innerHTML = htmlDecode(attorney.thumbnail);
    target.appendChild(frag);
    lazyLoadInstance.update()
  });
}

ready(() => {
  const home = document.querySelector('body.home')
  if(home){
    RandomProfiles('/our-attorneys/index.json',3,(data) => {
      populateAttorneys(data)
    })
  }
  const $lf = document.querySelector('.location-filter')
  if($lf){
    $lf.addEventListener('change', (e) => { 
      const els = document.querySelectorAll(`[data-location]`)
      if(e.target.value !== 'All'){
        els.forEach((el)=>{
          console.log(el)
          if(el.dataset.location !== e.target.value){
            el.classList.add('hide')
          }else{
            el.classList.remove('hide')
          }
        })
      }else{
        els.forEach((el)=>{
          el.classList.remove('hide')
        })
      } 
    })  
  }
})